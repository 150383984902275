import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Previewable from '../components/Previewable'
import SEO from '../components/SEO'
import { Banner } from '../acf/Banner/Banner.jsx'
import { FormBlock } from '../acf/FormBlock/FormBlock.jsx'
import { decodeEntities, getExcerpt } from '../utils/htmlParse'
import RelatedItemsBlock from '../components/RelatedItemsBlock'
import './post.scss'

const BlogPost = ({ data, location }) => {
  if (!data) return 'error'
  const { wordpressPost: post, site, relatedPosts } = data
  if (!post) return null
  const {
    title,
    content,
    featuredImage,
    yoast,
    categories,
    // date,
    // author,
    slug,
  } = post
  const { title: siteTitle, blogSlug } = site.siteMetadata
  return (
    <Layout location={location} className="blog-post-template">
      <SEO
        title={`${
          yoast.metaTitle
            ? decodeEntities(yoast.metaTitle)
            : `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
      />
      <Banner
        smallHeading="Article"
        heading={title}
        image={featuredImage}
        location={location}
        customCrumbs={[
          {
            link: '/',
            title: 'Home',
          },
          {
            link: `/${blogSlug}/`,
            title: 'News',
          },
          {
            link: `/${blogSlug}/${slug}/`,
            title: title,
          },
        ]}
      />
      <section className="post-container">
        <div className="wrapper">
          <div
            className="post-content-body"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </section>
      {categories && categories[0] && (
        <FormBlock
          heading={`Can't find what you're looking for? Contact us today.`}
          smallHeading="Contact"
          form="general"
          location={location}
        />
      )}
      {relatedPosts && relatedPosts.nodes && relatedPosts.nodes.length > 0 && (
        <RelatedItemsBlock
          heading="Related Articles"
          viewMoreText="View all articles"
          items={relatedPosts.nodes.map(p => ({
            ...p,
            excerpt: getExcerpt(p.content, 100),
          }))}
        />
      )}
    </Layout>
  )
}

export default Previewable(BlogPost, 'post')

export const pageQuery = graphql`
  query BlogPostByID($id: String!, $categorySlugs: [String]) {
    site {
      siteMetadata {
        title
        functionsUrl
        blogSlug
      }
    }
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      wordpress_id
      date(formatString: "MMMM DD, YYYY")
      featuredImage: featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      categories {
        name
        slug
      }
      yoast {
        metaTitle: title
        metaDescription: metadesc
      }
    }
    relatedPosts: allWordpressPost(
      filter: {
        categories: { elemMatch: { slug: { in: $categorySlugs } } }
        slug: { ne: "gatsby-build-post" }
        id: { ne: $id }
      }
      limit: 3
    ) {
      nodes {
        title
        content
        slug
        link
        featuredImage: featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
